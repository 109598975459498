import { type AllOptionalProperties } from '../../../core/types/AllOptionalProperties'
import { type IUser } from '../../../modules/auth/interfaces/IUser'
import { type PayloadAction } from '@reduxjs/toolkit'

export const userReducer = {
  setUser: (state: IUser, action: PayloadAction<IUser>) => {
    Object.entries(action.payload).forEach(([key, value]) => {
      state[key] = value
    })
  },

  updateUserState: (
    state: IUser,
    action: PayloadAction<AllOptionalProperties<IUser>>,
  ) => {
    return {
      ...state,
      ...action.payload,
    }
  },

  setToken: (state: IUser, action: PayloadAction<string>) => {
    state.token = action.payload
  },
}
