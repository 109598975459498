import { createSlice } from '@reduxjs/toolkit'
import LibraryReducers from '../../reducers/library/LibraryReducers'
import { type IFilterSelectedSourcesValues } from '../chatbot/filters/IFilters'

export type ILibraryDocument = Record<string, any>

export interface ILibraryDocumentsContent {
  isLoading: boolean
  total: number
  currPage: number
  limit: number
  pages: ILibraryDocument[][]
}

interface ILibraryDocTypes {
  sortBy: ILibraryDocumentsContent
  searchBarMatch: Record<string, ILibraryDocumentsContent>
}

export type ILibraryDocuments = Record<string, ILibraryDocTypes>

export interface ILibraryState {
  activeSource: IFilterSelectedSourcesValues[]
  documents: ILibraryDocuments
}

export const libraryInitialState: ILibraryState = {
  activeSource: [],
  documents: {},
}

const librarySlice = createSlice({
  initialState: libraryInitialState,
  name: 'library',
  reducers: LibraryReducers,
})

export default librarySlice.reducer
export const {
  buildLibraryState,
  setLibraryDocumentsPages,
  updateCurrPageLibraryDocuments,
  setLibraryDocumentsLoading,
  updateActiveSourceInLibrary,
  updateFilterSelectedValueLibrary,
  clearFiltersLibrary,
} = librarySlice.actions
