import type React from 'react'
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import {
  createClient,
  type RealtimePostgresChangesPayload,
} from '@supabase/supabase-js'
import useChatbotService from '../app/modules/dashboard/services/user/useChatbotService'
import { OwnerResourceRelationType } from '../app/redux/features/chatbot/filters/IFilters'
// import { useAppSelector } from '../app/redux/hooks/StoreHook'
import useAuthService from '../app/modules/auth/services/UseAuthService'
import { useAppDispatch, useAppSelector } from '../app/redux/hooks/StoreHook'
import { setUser } from '../app/redux/features/user/UserSlice'

// Create a context to hold the latest payload and subscription status
interface SupabaseRealtimeContextType {
  subscribe: (
    callback: (payload: RealtimePostgresChangesPayload<any>) => void,
  ) => () => void
  isConnected: boolean
  ownerResourceIds: {
    [key in OwnerResourceRelationType]?: number | null
  }
  reconnect: () => void
}

const SupabaseRealtimeContext = createContext<SupabaseRealtimeContextType>({
  subscribe: () => () => {},
  isConnected: false,
  ownerResourceIds: {},
  reconnect: () => {},
})

export const useSupabaseRealtime = (): SupabaseRealtimeContextType =>
  useContext(SupabaseRealtimeContext)

export const SupabaseRealtimeProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { getAccessToken } = useAuthService()
  const activeUser = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [supabase, setSupabase] = useState<any>(null)
  const [isConnected, setIsConnected] = useState(false)
  const [ownerResourceIds, setOwnerResourceIds] = useState<{
    [key in OwnerResourceRelationType]?: number | null
  }>({})
  const { getOwnerResources } = useChatbotService()
  const subscribers = useRef(
    new Set<(payload: RealtimePostgresChangesPayload<any>) => void>(),
  )
  const [shouldReconnect, setShouldReconnect] = useState(false)

  const subscribe = (
    callback: (payload: RealtimePostgresChangesPayload<any>) => void,
  ): (() => void) => {
    subscribers.current.add(callback)
    return () => {
      subscribers.current.delete(callback)
    }
  }

  // Initialize Supabase when component mounts or when shouldReconnect changes
  useEffect(() => {
    const initializeSupabase = async (): Promise<void> => {
      try {
        const { supabaseToken } = await getAccessToken()
        console.log('supabaseToken', supabaseToken)

        const supabaseClient = createClient(
          process.env.REACT_APP_SUPABASE_URL || '',
          process.env.REACT_APP_ANON_KEY || '',
          {
            accessToken: async () => supabaseToken,
          },
        )

        setSupabase(supabaseClient)

        // Fetch owner resources after reconnecting
        const response = await getOwnerResources()
        const newOwnerIds: {
          [key in OwnerResourceRelationType]?: number | null
        } = {}

        dispatch(
          setUser({
            ...activeUser,
            ownerResourceId: response?.TO_USER?.id || 0,
          }),
        )

        Object.values(OwnerResourceRelationType).forEach((relationType) => {
          if (response?.[relationType]?.id) {
            newOwnerIds[relationType] = response[relationType].id
          }
        })

        setOwnerResourceIds(newOwnerIds)

        // Reset the reconnect flag
        setShouldReconnect(false)
      } catch (error) {
        console.error('Failed to initialize Supabase client:', error)
      }
    }

    void initializeSupabase()
  }, [shouldReconnect]) // Remove activeUser from dependencies

  // Add a method to trigger reconnection
  const reconnect = useCallback((): void => {
    console.log('>> reconnecting from SupabaseRealtimeProvider')
    setShouldReconnect(true)
  }, [])

  // Fetch owner resource IDs for different relation types
  useEffect(() => {
    let isMounted = true

    const fetchOwnerResources = async (): Promise<void> => {
      try {
        const response = await getOwnerResources()
        if (!isMounted) return

        const newOwnerIds: {
          [key in OwnerResourceRelationType]?: number | null
        } = {}
        dispatch(
          setUser({
            ...activeUser,
            ownerResourceId: response?.TO_USER?.id || 0,
          }),
        )

        Object.values(OwnerResourceRelationType).forEach((relationType) => {
          if (response?.[relationType]?.id) {
            newOwnerIds[relationType] = response[relationType].id
          }
        })

        setOwnerResourceIds(newOwnerIds)
      } catch (error) {
        console.error('Failed to fetch owner resources:', error)
      }
    }

    void fetchOwnerResources()

    return () => {
      isMounted = false
    }
  }, []) // Remove getOwnerResources from dependencies

  // Only set up subscriptions when supabase client is ready
  useEffect(() => {
    if (!supabase) return

    // Only proceed if we have at least one owner ID
    const validOwnerIds = Object.entries(ownerResourceIds).filter(
      ([_, id]) => id !== null && id !== undefined,
    )

    if (validOwnerIds.length === 0) return

    // Take only the first valid owner ID for debugging
    const [firstRelationType, firstId] = validOwnerIds[0]

    // Create the channel
    const channel = supabase.channel('global-resource-changes')

    // Add handler only for the first valid owner ID
    channel.on(
      'postgres_changes' as any,
      {
        event: '*',
        schema: 'public',
        table: 'resource',
        // filter: `resources=eq.${firstId}`,
      },
      (payload: RealtimePostgresChangesPayload<any>) => {
        console.log(
          `Received update for ${firstRelationType} (ID: ${firstId}): `,
          payload,
        )
        // Notify all subscribers
        subscribers.current.forEach((callback) => {
          callback(payload)
        })
      },
    )

    // Subscribe to the channel
    channel.subscribe((status: string) => {
      setIsConnected(status === 'SUBSCRIBED')
      if (status === 'SUBSCRIBED') {
        console.log(
          'Successfully subscribed to first valid owner ID:',
          firstRelationType,
          firstId,
        )
      } else {
        console.log(
          'Failed to subscribe to first valid owner ID:',
          firstRelationType,
          firstId,
        )
      }
    })

    return () => {
      void channel.unsubscribe()
    }
  }, [supabase, JSON.stringify(ownerResourceIds)])

  // Don't render children until Supabase is initialized
  if (!supabase) {
    return null // or a loading spinner
  }

  return (
    <SupabaseRealtimeContext.Provider
      value={{ subscribe, isConnected, ownerResourceIds, reconnect }}
    >
      {children}
    </SupabaseRealtimeContext.Provider>
  )
}
