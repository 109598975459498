import { useSearchParams } from 'react-router-dom'
import useTranscriptionsService from '../../../services/user/useTranscriptionsService'
import { useAppDispatch } from '../../../../../redux/hooks/StoreHook'
import {
  clearTranscriptionsList,
  setAreTranscriptionsLoading,
  setCurrentPage,
  setPendingOrProcessingList,
  setTrancriptionsByPage,
  setTranscriptionsTotal,
  updateIsInitialRequestDone,
} from '../../../../../redux/features/transcriptions/TranscriptionsTableSlice'

const useGetTranscriptions = (): (({
  limit,
  page,
  clearTranscriptions,
}: {
  limit: string
  page: string
  clearTranscriptions?: boolean
}) => void) => {
  const [params, setParams] = useSearchParams()
  const { list } = useTranscriptionsService()
  const dispatch = useAppDispatch()

  const getTranscriptions = ({
    limit,
    page,
    clearTranscriptions,
  }: {
    limit: string
    page: string
    clearTranscriptions?: boolean
  }): void => {
    params.set('limit', limit)
    params.set('page', page)
    setParams(params)

    if (params.get('limit') !== null && params.get('page') !== null) {
      list({
        payload: {
          limit: params.get('limit') ?? '15',
          page: params.get('page') ?? '0',
        },
      })
        .then((res: any) => {
          clearTranscriptions && dispatch(clearTranscriptionsList())
          dispatch(updateIsInitialRequestDone(true))
          dispatch(
            setTranscriptionsTotal(Number(res.headers['x-pagination-count'])),
          )
          dispatch(setCurrentPage(Number(page)))
          dispatch(setTrancriptionsByPage({ page, items: res.data }))
          dispatch(setPendingOrProcessingList([]))
        })
        .catch((err: any) => {
          console.error(err)
        })
        .finally(() => {
          dispatch(setAreTranscriptionsLoading(false))
        })
    }
  }
  return getTranscriptions
}

export default useGetTranscriptions
