import { type PayloadAction } from '@reduxjs/toolkit'
import { type ILibraryState } from '../../features/library/LibrarySlice'
import {
  type FilterFieldKeys,
  type IFilterSourcesOptions,
} from '../../features/chatbot/filters/IFilters'
import { removeNumberAbbreviation } from '../../../utilities/handleNumberAbbreviation'

const LibraryReducers = {
  buildLibraryState: (
    state: ILibraryState,
    action: PayloadAction<IFilterSourcesOptions[]>,
  ) => {
    action.payload.forEach((filterOption, index) => {
      const totalDocs = filterOption.description.split(' ')[0]
      const totalDocsNumber = removeNumberAbbreviation(totalDocs)

      state.documents[filterOption.key] = {
        sortBy: {
          isLoading: true,
          total: totalDocsNumber,
          currPage: 0,
          limit: 20,
          pages: [],
        },
        searchBarMatch: {},
      }

      state.activeSource[index] = {
        key: filterOption.key,
        label: filterOption.label,
        active: index === 0,
        fields: [],
      }
    })
  },

  setLibraryDocumentsPages: (
    state: ILibraryState,
    action: PayloadAction<{
      page: number
      searchValue: string
      list: any[]
      filterOptionKey: string
      reset?: boolean
      totalDocs?: string
    }>,
  ) => {
    const { page, searchValue, list, filterOptionKey, reset, totalDocs } =
      action.payload

    const documentsContent = searchValue
      ? state.documents[filterOptionKey].searchBarMatch[searchValue]
      : state.documents[filterOptionKey].sortBy

    if (reset) {
      documentsContent.pages = []
    }
    if (totalDocs) {
      documentsContent.total = removeNumberAbbreviation(totalDocs)
    }

    documentsContent.currPage = page
    documentsContent.pages[page] = list

    if (searchValue) {
      state.documents[filterOptionKey].searchBarMatch[searchValue] =
        documentsContent
    } else {
      state.documents[filterOptionKey].sortBy = documentsContent
    }
  },

  updateCurrPageLibraryDocuments: (
    state: ILibraryState,
    action: PayloadAction<{
      trigger: 'NEXT' | 'PREV'
      filterOptionKey: string
      searchValue: string
    }>,
  ) => {
    const { trigger, filterOptionKey, searchValue } = action.payload
    const currPage = searchValue
      ? state.documents[filterOptionKey].searchBarMatch[searchValue].currPage
      : state.documents[filterOptionKey].sortBy.currPage

    if (searchValue) {
      state.documents[filterOptionKey].searchBarMatch[searchValue].currPage =
        trigger === 'NEXT' ? currPage + 1 : currPage - 1
    } else {
      state.documents[filterOptionKey].sortBy.currPage =
        trigger === 'NEXT' ? currPage + 1 : currPage - 1
    }
  },

  setLibraryDocumentsLoading: (
    state: ILibraryState,
    action: PayloadAction<{
      filterOptionKey: string
      searchValue: string
      loading: boolean
    }>,
  ) => {
    const { filterOptionKey, searchValue, loading } = action.payload

    const documentsContent = searchValue
      ? state.documents[filterOptionKey].searchBarMatch[searchValue]
      : state.documents[filterOptionKey].sortBy

    if (documentsContent) {
      documentsContent.isLoading = loading
    }

    if (searchValue && !documentsContent) {
      const mockTotal = 30
      state.documents[filterOptionKey].searchBarMatch[searchValue] = {
        isLoading: true,
        total: mockTotal,
        currPage: 0,
        limit: 20,
        pages: [],
      }
    }
  },

  updateActiveSourceInLibrary: (
    state: ILibraryState,
    action: PayloadAction<{
      filterOptionKey: string
    }>,
  ) => {
    state.activeSource = state.activeSource.map((source) => ({
      ...source,
      active: source.key === action.payload.filterOptionKey,
    }))
  },

  updateFilterSelectedValueLibrary: (
    state: ILibraryState,
    action: PayloadAction<{
      sourceKey: string
      fieldKey: FilterFieldKeys
      filterValue: Array<string | number> | number | null
    }>,
  ) => {
    const newState = state.activeSource.map((source) =>
      source.key === action.payload.sourceKey
        ? {
            ...source,
            fields: [
              ...(source.fields ?? []).filter(
                (filter) => filter.key !== action.payload.fieldKey,
              ),
              {
                key: action.payload.fieldKey,
                ...(Array.isArray(action.payload.filterValue)
                  ? { options: action.payload.filterValue }
                  : { value: action.payload.filterValue }),
              },
            ],
          }
        : source,
    )

    state.activeSource = newState
  },

  clearFiltersLibrary: (
    state: ILibraryState,
    action: PayloadAction<{
      sourceKey: string
    }>,
  ) => {
    state.activeSource = state.activeSource.map((source) =>
      source.key === action.payload.sourceKey
        ? {
            ...source,
            fields: [],
          }
        : source,
    )
  },
}

export default LibraryReducers
