import type React from 'react'
import { useResourceChanges } from '../../hooks/useResourceChanges'
import useTranscriptions from '../modules/dashboard/pages/transcriptions/hooks/useTranscriptions'

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { notifyNewTranscription } = useTranscriptions()

  useResourceChanges((payload) => {
    notifyNewTranscription(payload)
  })

  return <>{children}</>
}
