import useApiSecureAxiosHttpClient from '../../../core/http-clients/ApiSecureArielAxiosHttpClient'
import { type IUseAuthService } from './IUseAuthService'

const useAuthService = (): IUseAuthService => {
  const { get, post } = useApiSecureAxiosHttpClient()

  const auth = async (): Promise<any> => {
    return await get({
      path: 'auth',
    })
  }

  const logoutService = async (): Promise<any> => {
    return await post({
      path: 'auth/logout',
    })
  }

  const getAccessToken = async (): Promise<any> => {
    console.log('getAccessToken')
    const response = await get({
      path: 'users/user/access-token',
    })
    console.log('getAccessToken response', response)
    return response
  }

  return {
    auth,
    logoutService,
    getAccessToken,
  }
}

export default useAuthService
