export const addNumberAbbreviation = (num: number): string => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
  }

  return String(num)
}

export const removeNumberAbbreviation = (numAbb: string): number => {
  const num = parseFloat(numAbb)

  if (numAbb.includes('k') || numAbb.includes('mil')) return num * 1000
  if (numAbb.includes('M')) return num * 1000000
  if (numAbb.includes('B')) return num * 1000000000

  return num
}
