import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../redux/hooks/StoreHook'
import {
  setAreTranscriptionsLoading,
  setCurrentPage,
  setPendingOrProcessingList,
} from '../../../../../redux/features/transcriptions/TranscriptionsTableSlice'
import { TableStatusTypes } from '../../../../../core/components/table/status/enums/TableStatusTypes'
import { showToast } from '../../../../../redux/features/toast/ToastSlice'
import { ToastTypes } from '../../../../../core/components/toast/enum/ToastTypes'
import useGetTranscriptions from './useGetTranscriptions'

const useTranscriptions = (): any => {
  const [params, setParams] = useSearchParams()
  const getTranscriptions = useGetTranscriptions()
  const { transcriptionsByPages, isInitialRequestDone, currentPage } =
    useAppSelector((state) => state.transcriptionsTable)
  const dispatch = useAppDispatch()
  const limit = params.get('limit') ?? '20'
  const page = params.get('page') ?? '0'

  useEffect(() => {
    if (!isInitialRequestDone) {
      getTranscriptions({ limit, page })
    } else {
      dispatch(setAreTranscriptionsLoading(false))
    }
  }, [])

  useEffect(() => {
    if (transcriptionsByPages[`page${currentPage}`]) {
      let processingOrPendingFound = transcriptionsByPages[
        `page${currentPage}`
      ].filter(
        (item) =>
          item.status === TableStatusTypes.PENDING ||
          item.status === TableStatusTypes.PROCESSING,
      )

      if (processingOrPendingFound.length > 0) {
        processingOrPendingFound = processingOrPendingFound.map(
          (item) => item.uuid,
        )
        dispatch(setPendingOrProcessingList(processingOrPendingFound))
        getTranscriptions({
          limit,
          page: String(currentPage),
        })
      }
    }
  }, [currentPage])

  const notifyNewTranscription = (payload: any): void => {
    if (
      payload.new.type === 'MULTIMEDIA' &&
      payload.new.status === 'COMPLETED'
    ) {
      dispatch(
        showToast({
          type: ToastTypes.SUCCESS,
          description: `Transcription completada: ${payload.new.name}`,
          isVisible: true,
          timeToClose: 6500,
        }),
      )
    }
  }

  const handleNextPage = (): void => {
    params.set('page', String(currentPage + 1))
    setParams(params)

    if (transcriptionsByPages[`page${currentPage + 1}`]) {
      dispatch(setCurrentPage(currentPage + 1))
    } else {
      dispatch(setAreTranscriptionsLoading(true))
      getTranscriptions({
        limit,
        page: String(currentPage + 1),
      })
    }
  }

  const handlePrevPage = (): void => {
    params.set('page', String(currentPage - 1))
    setParams(params)

    if (transcriptionsByPages[`page${currentPage - 1}`]) {
      dispatch(setCurrentPage(currentPage - 1))
    } else {
      dispatch(setAreTranscriptionsLoading(true))
      getTranscriptions({
        limit,
        page: String(currentPage - 1),
      })
    }
  }

  return {
    limit,
    handleNextPage,
    handlePrevPage,
    notifyNewTranscription,
  }
}

export default useTranscriptions
