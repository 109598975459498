import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../features/user/UserSlice'
import asideSlice from '../features/aside/AsideSlice'
import companySlice from '../features/company/CompanySlice'
import lateralMenuSlice from '../features/lateral-menu/LateralMenuSlice'
import toastSlice from '../features/toast/ToastSlice'
import modalSlice from '../features/modal/ModalSlice'
import alertSlice from '../features/alert/AlertSlice'
import ActiveChatSlice from '../features/chatbot/active-chat/ActiveChatSlice'
import ChatsHistorySlice from '../features/chatbot/chats-history/ChatsHistorySlice'
import PaginationChatsHistory from '../features/chatbot/chats-history/pagination/PaginationChatsHistorySlice'
import tierSlice from '../features/tier/TierSlice'
import transcriptionsTableSlice from '../features/transcriptions/TranscriptionsTableSlice'
import ChatSettingsSlice from '../features/chatbot/chat-settings/ChatSettings'
import filterOptionsSlice from '../features/chatbot/filters/FilterOptionsSlice'
import devicesLimitSlice from '../features/devices-limit/DevicesLimitSlice'
import librarySlice from '../features/library/LibrarySlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    company: companySlice,
    chatsHistory: ChatsHistorySlice,
    paginationChatsHistory: PaginationChatsHistory,
    filterOptions: filterOptionsSlice,
    activeChat: ActiveChatSlice,
    chatSettings: ChatSettingsSlice,
    lateralMenu: lateralMenuSlice,
    toast: toastSlice,
    aside: asideSlice,
    modal: modalSlice,
    alert: alertSlice,
    tier: tierSlice,
    transcriptionsTable: transcriptionsTableSlice,
    devicesLimit: devicesLimitSlice,
    library: librarySlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
