export enum OwnerResourceRelationType {
  TO_STAFF = 'TO_STAFF',
  TO_COMPANY = 'TO_COMPANY',
  TO_USER = 'TO_USER',
  TO_CASE = 'TO_CASE',
}

export enum FilterFieldKeys {
  AUTHORS = 'authors',
  TAGS = 'tags',
  CATEGORIES = 'categories',
  KEYWORDS = 'keywords',
  YEARS = 'years',
  FROM_YEAR = 'from_year',
  TO_YEAR = 'to_year',
  EXTERNAL_ID = 'external_id',
}

export enum FilterSourceKeys {
  PUBLIC_SEARCH = 'public_search',
  CASE_SEARCH = 'case_search',
  COMPANY_SEARCH = 'company_search',
  USER_DOCUMENT = 'user_document',
}

export enum FilterSourceType {
  MULTI_SELECT = 'multi-select',
  RANGE = 'range',
}

export interface IFilterSelectedValues {
  sources?: IFilterSelectedSourcesValues[]
}

export interface IFilterSelectedSourcesValues {
  // key: FilterSourceKeys
  owner_id?: string
  id?: string
  key: string
  label: string
  active: boolean
  fields: IFilterFieldSelectedValues[]
}

export interface IFilterFieldSelectedValues {
  key: FilterFieldKeys
  value?: number | null
  options?: Array<string | number>
}

export interface IFilterItemSelectionOptions {
  name: string
  code: string
}

export interface IFilterSourcesOptions {
  avatar: string
  label: string
  owner_id?: string
  // key: FilterSourceKeys
  key: string
  description: string
  filters?: IFilterFieldOptions[]
}

export interface IFilterFieldOptions {
  key: FilterFieldKeys
  label: string
  type: FilterSourceType
  options?: IFilterItemSelectionOptions[] | string[]
  from?: number
  to?: number
}

export const defaulfSelectedFilterValues: IFilterSelectedValues = {
  sources: [
    {
      key: FilterSourceKeys.PUBLIC_SEARCH,
      label: 'Biblioteca Legal Ariel',
      active: true,
      fields: [],
    },
  ],
}

export const defaultFilters: IFilterSelectedValues = {
  sources: [],
}

export const isAttachedSource = (key: string): boolean => {
  if (key === FilterSourceKeys.USER_DOCUMENT) {
    return true
  }
  return false
}
