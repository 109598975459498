import { useEffect } from 'react'
import { type RealtimePostgresChangesPayload } from '@supabase/supabase-js'
import { useSupabaseRealtime } from '../providers/SupabaseRealtimeProvider'

/**
 * Hook to subscribe to Supabase resource table changes
 * @param callback Function to execute when changes are detected
 * @param resourceType Optional resource type to filter changes
 */
export const useResourceChanges = (
  callback: (payload: RealtimePostgresChangesPayload<any>) => void,
  resourceType?: string,
): void => {
  const { subscribe } = useSupabaseRealtime()

  useEffect((): (() => void) => {
    const handlePayload = (
      payload: RealtimePostgresChangesPayload<any>,
    ): void => {
      if (!resourceType || payload.new?.type === resourceType) {
        callback(payload)
      }
    }

    return subscribe(handlePayload)
  }, [callback, resourceType, subscribe])
}
