import { getResponseType } from '../../../../core/enums/GetResponseType'
import useApiSecureAxiosHttpClient from '../../../../core/http-clients/ApiSecureArielAxiosHttpClient'
import { useChatbotMock } from '../../../../mocks/services/chatbot/useChatbotMock'
import { transcriptionsMock } from '../../../../mocks/services/transcriptions/transcriptionsMock'
import { type IGenericRequestProps } from '../interfaces/IGenericRequestProps'
import {
  type ICreateChatTranscriptionPayload,
  type ICreateTranscriptionPayload,
  type IDeleteFilePayload,
  type IGetTranscriptionPayload,
  type IListPayloadPayload,
  type IRetryCreateSummaryPayload,
  type IUploadFilePayload,
  type ISendTranscriptionMessagePayload,
  type IUpdateFileNamePayload,
  type IUseTranscriptionsService,
} from '../interfaces/IUseTranscriptionsService'

const useTranscriptionsService = (): IUseTranscriptionsService => {
  const { get, post, put, deleteRequest } = useApiSecureAxiosHttpClient()
  const { getTranscriptionsMock } = transcriptionsMock()
  const { createChatMock } = useChatbotMock()

  const list = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<IListPayloadPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(getTranscriptionsMock(payload.limit))
      })
    }
    return await get({
      path: `resources?limit=${payload.limit}&page=${payload.page}`,
      responseType: getResponseType.HEADERS_AND_DATA,
    })
  }

  const listCategoryTags = async (): Promise<any> => {
    return await get({
      path: 'resources/tags',
    })
  }

  const getTranscription = async ({
    payload,
  }: IGenericRequestProps<IGetTranscriptionPayload>): Promise<any> => {
    return await get({
      path: `resources/child/fragments/${payload.uuid}`,
    })
  }

  const getTranscriptionsTimeLeft = async (): Promise<any> => {
    return await get({
      path: `resources/transcription-time-left`,
    })
  }

  const getCategoryTypes = async (): Promise<any> => {
    return await get({
      path: 'resources/tags?category=DOCUMENTS',
    })
  }

  const getCategoryTopics = async (): Promise<any> => {
    return await get({
      path: 'resources/tags?category=TOPIC',
    })
  }

  const uploadFile = async ({
    payload,
    customConfig,
  }: IGenericRequestProps<IUploadFilePayload>): Promise<any> => {
    return await post({
      path: 'azure/blob-storage/audio-transcription',
      payload: payload.formData,
      customConfig,
    })
  }

  const createChat = async ({
    payload,
    isMock = false,
  }: IGenericRequestProps<ICreateChatTranscriptionPayload>): Promise<any> => {
    if (isMock) {
      return await new Promise((resolve) => {
        resolve(createChatMock)
      })
    }
    return await post({
      path: 'chat/channel',
      payload,
    })
  }

  const createTranscription = async ({
    payload,
  }: IGenericRequestProps<ICreateTranscriptionPayload>): Promise<any> => {
    return await post({
      path: 'resources',
      payload,
    })
  }

  const sendTranscriptionMessage = async ({
    payload,
  }: IGenericRequestProps<ISendTranscriptionMessagePayload>): Promise<any> => {
    return await post({
      path: 'chat/completions',
      payload,
    })
  }

  const retryCreateSummary = ({
    payload,
  }: IGenericRequestProps<IRetryCreateSummaryPayload>): any => {
    return post({
      path: `resources/admin-resources/re-process/${payload.id}`,
    })
  }

  const updateFileName = ({
    payload,
  }: IGenericRequestProps<IUpdateFileNamePayload>): any => {
    return put({
      path: `resources/${payload.uuid}`,
      payload,
    })
  }

  const deleteFile = async ({
    payload,
  }: IGenericRequestProps<IDeleteFilePayload>): Promise<any> => {
    return await deleteRequest({
      path: `resources/${payload.uuid}`,
    })
  }

  return {
    list,
    listCategoryTags,
    getTranscription,
    getTranscriptionsTimeLeft,
    getCategoryTypes,
    getCategoryTopics,
    uploadFile,
    createChat,
    createTranscription,
    sendTranscriptionMessage,
    retryCreateSummary,
    updateFileName,
    deleteFile,
  }
}

export default useTranscriptionsService
