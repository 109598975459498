;(function () {
  const nonceValue = crypto.randomUUID()

  const metaCSP = document.createElement('meta')
  metaCSP.httpEquiv = 'Content-Security-Policy'
  metaCSP.content = `
          default-src 'self';          
          script-src 'self' https://*.googletagmanager.com https://tagmanager.google.com https://*.google.com https://*.google-analytics.com https://*.googletagmanager.com https://googleads.g.doubleclick.net 'nonce-${nonceValue}' https://*.hotjar.com https://*.hotjar.io wss://ws.hotjar.com ;          
          connect-src 'self' blob: https://storagearieldev.blob.core.windows.net https://*.blob.core.windows.net ${process.env.API_ARIEL_URL} ${process.env.WEBAPP_URL} ${process.env.REACT_APP_SUPABASE_URL} wss://${process.env.REACT_APP_SUPABASE_URL.replace('https://', '')} https://api.pda.com.co https://api.airtable.com https://*.google-analytics.com https://google.com https://*.google.com https://*.google.ca https://*.googleapis.com https://*.gstatic.com https://*.googletagmanager.com https://*.doubleclick.net https://*.hotjar.com https://*.hotjar.io wss://ws.hotjar.com https://jsonplaceholder.typicode.com;
          img-src https://www.google.com https://www.google.com.co https://www.googletagmanager.com blob: ${process.env.API_ARIEL_URL} ${process.env.WEBAPP_URL} ${process.env.SELF_HOST} ${process.env.SUPABASE_IMG_SRC};
          style-src 'self' https://fonts.googleapis.com 'unsafe-inline';
          font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
          frame-src https://td.doubleclick.net https://www.googletagmanager.com;
        `
  document.head.appendChild(metaCSP)

  function createScript(content) {
    const script = document.createElement('script')
    script.nonce = nonceValue
    script.textContent = content
    document.head.appendChild(script)
  }

  // Google Tag Manager
  createScript(`
          ;(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-PMK634N2');
        `)

  // Google tag de Alberto (gtag.js)
  createScript(`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-VP6VN4GBR0');
        `)
})()
