import { useEffect, useState } from 'react'
import { type IUseDetailConsultation } from '../interfaces/IUseDetailConsultation'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../redux/hooks/StoreHook'
import { useParams } from 'react-router-dom'
import useChatbotService from '../../../../../services/user/useChatbotService'
import {
  clearActiveChat,
  setActiveChat,
} from '../../../../../../../redux/features/chatbot/active-chat/ActiveChatSlice'
import { ChatbotMessageRols } from '../../../../../../../core/components/chatbot/message/enum/ChatbotMessageTypes'
import {
  addMessagesFromPagination,
  addNewChatToHistory,
  setAreMessagesPaginationLoading,
} from '../../../../../../../redux/features/chatbot/chats-history/ChatsHistorySlice'
import {
  type IChatbotMessageMetadata,
  type ISearchProcess,
  type ISource,
  type ICompanySource,
  type IPublicSource,
  isCompanySource,
  type IChatbotMessage,
} from '../../../../../../../core/components/chatbot/message/interfaces/IChatbotMessage'
import { useTranslation } from 'react-i18next'
import {
  type IChatSettings,
  type IChatHistoryItem,
} from '../../../../../../../redux/features/chatbot/chats-history/IChatsHistory'
import { detailConsultationLastQsObservable$ } from '../../../../../observables/suggested-questions-last-message/detail-consultation/detailConsultationLastQsObservable'
import {
  defaulfSelectedFilterValues,
  FilterSourceKeys,
  type IFilterSelectedValues,
} from '../../../../../../../redux/features/chatbot/filters/IFilters'
import { setFilterOptions } from '../../../../../../../redux/features/chatbot/filters/FilterOptionsSlice'
import { isAttachedSource } from '../../../../../../../redux/features/chatbot/filters/IFilters'
import useIntersectionObserver from '../../../../../../../core/hooks/intersection-observer/useIntersectionObserver'

export function mergeMetadata(
  existingMetadata: IChatbotMessageMetadata,
  newMetadata: IChatbotMessageMetadata,
): IChatbotMessageMetadata {
  return {
    sources: [
      ...(existingMetadata.sources ?? []),
      ...(newMetadata.sources ?? []),
    ],
    firstSearch: mergeSearchProcesses(
      existingMetadata.firstSearch,
      newMetadata.firstSearch,
    ),
    secondSearch: mergeSearchProcesses(
      existingMetadata.secondSearch,
      newMetadata.secondSearch,
    ),
    allResultsCount:
      newMetadata.allResultsCount ?? existingMetadata.allResultsCount,
  }
}

function mergeSearchProcesses(
  existingProcesses?: Record<number, ISearchProcess>,
  newProcesses?: Record<number, ISearchProcess>,
): Record<number, ISearchProcess> | undefined {
  if (existingProcesses && newProcesses) {
    const mergedProcesses: Record<number, ISearchProcess> = {
      ...existingProcesses,
    }

    Object.keys(newProcesses).forEach((id) => {
      const numericId = Number(id)
      if (isNaN(numericId)) {
        // Optionally handle invalid IDs
        console.warn(`Invalid process ID: ${id}`)
        return
      }

      const existingProcess = existingProcesses[numericId] || {
        id: numericId,
        results: [],
      }
      const newProcess = newProcesses[numericId]

      mergedProcesses[numericId] = mergeSearchProcess(
        existingProcess,
        newProcess,
      )
    })

    return mergedProcesses
  }

  return existingProcesses ?? newProcesses
}

function mergeSearchProcess(
  existingProcess: ISearchProcess,
  newProcess: ISearchProcess,
): ISearchProcess {
  return {
    id: existingProcess.id || newProcess.id,
    searchQuery: newProcess.searchQuery || existingProcess.searchQuery,
    queryReason: newProcess.queryReason || existingProcess.queryReason,
    isPrivate:
      newProcess.isPrivate !== undefined
        ? newProcess.isPrivate
        : existingProcess.isPrivate,
    results:
      newProcess.results && newProcess.results.length > 0
        ? newProcess.results
        : existingProcess.results,
  }
}

export function mapResponseToMetadata(response: any): IChatbotMessageMetadata {
  const metadata: IChatbotMessageMetadata = {}

  // Map first search
  if (response['1st_search']) {
    metadata.firstSearch = mapSearchProcess(response['1st_search'])
  }

  // Map second search
  if (response['2nd_search']) {
    metadata.secondSearch = mapSearchProcess(response['2nd_search'])
  }

  // Map first search
  if (response.first_search) {
    metadata.firstSearch = mapSearchProcess(response.first_search)
  }

  // Map second search
  if (response.second_search) {
    metadata.secondSearch = mapSearchProcess(response.second_search)
  }

  // Map citations to sources
  if (response.citations) {
    metadata.sources = response.citations.map(mapSource)
  }

  // Map citations to sources
  if (response.sources) {
    metadata.sources = response.sources.map(mapSource)
  }

  // Map all results count
  if (
    response.selection &&
    typeof response.selection.all_results_count === 'number'
  ) {
    metadata.allResultsCount = response.selection.all_results_count
  }

  return metadata
}

function mapSearchProcess(searchData: any): Record<number, ISearchProcess> {
  const processes: Record<number, ISearchProcess> = {}

  Object.keys(searchData).forEach((key) => {
    const queryData = searchData[key]
    const id = parseInt(key.replace('query_', ''), 10) // Extract the ID from 'query_#'

    // Initialize or update the search process
    const existingProcess = processes[id] || {
      id,
      searchQuery: '',
      queryReason: '',
      isPrivate: false,
      results: [],
    }

    // Update the existing process with new data
    if (queryData.search_query) {
      existingProcess.searchQuery = queryData.search_query
    }
    if (queryData.query_reason) {
      existingProcess.queryReason = queryData.query_reason
    }
    if (Object.prototype.hasOwnProperty.call(queryData, 'is_private')) {
      existingProcess.isPrivate = queryData.is_private
    }
    if (Array.isArray(queryData.results)) {
      existingProcess.results = queryData.results.map(mapSource)
    }

    processes[id] = existingProcess
  })

  return processes
}

function mapSource(sourceData: any): ISource {
  if (isCompanySource(sourceData)) {
    const source: ICompanySource = {
      position: sourceData.position || 0,
      score: sourceData.score || 0,
      rerank: sourceData.rerank || 0,
      captions: sourceData.captions || '',
      id: sourceData.id || '',
      title: sourceData.title || '',
      category: sourceData.category || '',
      tags: sourceData.tags || [],
      uploaded_by_id: sourceData.uploaded_by_id || '',
      page: sourceData.page || 0,
      year: sourceData.year || 0,
      file_path: sourceData.file_path || '',
      external_id: sourceData.external_id || '',
      content: sourceData.content || '',
      fragment_number: sourceData.fragment_number || 0,
    }
    return source
  } else {
    const source: IPublicSource = {
      position: sourceData.position || 0,
      score: sourceData.score || 0,
      rerank: sourceData.rerank || 0,
      captions: sourceData.captions || '',
      id: sourceData.id || '',
      title: sourceData.title || '',
      author: sourceData.author || '',
      keywords: sourceData.keywords || '',
      category: sourceData.category || '',
      page: sourceData.page || 0,
      year: sourceData.year || 0,
      has_copyright: sourceData.has_copyright || false,
      file_path: sourceData.file_path || '',
      external_id: sourceData.external_id || '',
      content: sourceData.content || '',
      fragment_number: sourceData.fragment_number || 0,
    }
    return source
  }
}

const useDetailConsultation = (): IUseDetailConsultation => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [initialScrollDone, setInitialScrollDone] = useState(false)
  const [blockGetMessagesIntersection, setBlockGetMessagesIntersection] =
    useState(true)
  const [suggestedQuestionsLastMessage, setSuggestedQuestionsLastMessage] =
    useState<string[]>([])
  const chatsHistoryState = useAppSelector((state) => state.chatsHistory)
  const chatSettingsState = useAppSelector((state) => state.chatSettings)
  const filterOptionsState = useAppSelector((state) => state.filterOptions)
  const activeChatState = useAppSelector((state) => state.activeChat)
  const detailConsultationLastQsObserver$ =
    detailConsultationLastQsObservable$.getSubject()
  const { getMessages, getChatSettings, getFilterOptions, getInfoNoMessages } =
    useChatbotService()
  const { referenceIdentifier } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setIntersectionConfig } = useIntersectionObserver()
  const isTemporary = window.location.pathname.includes('/temporary/')

  useEffect(() => {
    return () => {
      setInitialScrollDone(false)
      setBlockGetMessagesIntersection(true)
      setIsLoading(true)
      dispatch(clearActiveChat())
    }
  }, [referenceIdentifier])

  useEffect(() => {
    const panel = document.querySelector('.panel__mainContainer')

    if (activeChatState.uuid && !initialScrollDone && panel) {
      setTimeout(() => {
        panel.scrollTo({
          top: panel.scrollHeight,
        })
      }, 130)

      setTimeout(() => {
        setInitialScrollDone(true)
        setBlockGetMessagesIntersection(false)
      }, 1000)
    }
  }, [activeChatState])

  useEffect(() => {
    if (referenceIdentifier && referenceIdentifier !== 'loading') {
      const chatFoundInChatsHistory = chatsHistoryState.find(
        (item) => item.uuid === referenceIdentifier,
      )

      const DidUpdateInActiveChat = updateActiveChatWithHistoryChat(
        chatFoundInChatsHistory,
      )

      if (!DidUpdateInActiveChat) {
        if (filterOptionsState.length === 0) {
          void (async () => {
            try {
              const res = await getFilterOptions()
              dispatch(setFilterOptions(res))
            } catch (error) {
              console.error('Error fetching filter options:', error)
            }
          })()
        }

        if (isTemporary) {
          const loadTemporaryChat = async (): Promise<void> => {
            try {
              const settings = await getChatSettings()
              const chatSetting: IChatSettings = settings.find(
                (setting: { uuid: string }) =>
                  setting.uuid === referenceIdentifier,
              )

              if (!chatSetting) {
                console.error('Chat setting not found')
                return
              }

              const newInfoForChat: IChatHistoryItem = {
                uuid: chatSetting.uuid,
                resource_id: null,
                chat_settings: {
                  uuid: chatSetting.uuid,
                  title: chatSetting.title,
                  default_filters: chatSetting.default_filters,
                  description: chatSetting.description,
                  image: chatSetting.image,
                  color: chatSetting.color,
                  case: chatSetting.case,
                  suggested_questions: chatSetting.suggested_questions,
                },
                filters: completeFiltersWithOptions(
                  chatSetting.default_filters,
                ),
                name: chatSetting.title,
                messages: {
                  list: [],
                  pagination: {
                    isLoading: false,
                    cursorPaginationRef: '',
                  },
                },
                suggested_questions: chatSetting.suggested_questions,
                isReviewed: true,
                isLoadingResponse: false,
                isOutOfPagination: false,
                isTemporary: true,
                isSendbarDisabled: false,
                document_text: null,
                attachments: [],
              }

              dispatch(addNewChatToHistory(newInfoForChat))
              dispatch(setActiveChat(newInfoForChat))
            } catch (err) {
              console.error('Error getting chat settings:', err)
            } finally {
              setIsLoading(false)
            }
          }

          void loadTemporaryChat()
        } else {
          let newInfoForChat: IChatHistoryItem

          getInfoNoMessages({ payload: { uuid: referenceIdentifier } })
            .then((res) => {
              let filters: IFilterSelectedValues = defaulfSelectedFilterValues
              if (res.filters) {
                filters = Array.isArray(res.filters.sources)
                  ? res.filters
                  : { sources: [] }
              } else {
                filters =
                  res.chat_setting.default_filters &&
                  Array.isArray(res.chat_setting.default_filters.sources)
                    ? res.chat_setting.default_filters
                    : { sources: [] }
              }
              if (!filters.sources) {
                filters = defaulfSelectedFilterValues
              } else {
                // Check if there are any non-attached sources
                const hasNonAttachedSources =
                  Array.isArray(filters.sources) &&
                  filters.sources.some(
                    (source) => !isAttachedSource(source.key),
                  )

                if (!hasNonAttachedSources) {
                  // Add default sources while preserving any attached sources
                  filters = {
                    ...filters,
                    sources: [
                      ...(Array.isArray(filters.sources)
                        ? filters.sources
                        : []),
                      ...(defaulfSelectedFilterValues.sources ?? []),
                    ],
                  }
                }
              }

              newInfoForChat = {
                uuid: res.uuid,
                resource_id: res.resource_id,
                chat_settings: {
                  uuid: res.chat_setting.uuid,
                  title: res.chat_setting.title,
                  default_filters: res.chat_setting.default_filters,
                  description: res.chat_setting.description,
                  image: res.chat_setting.image,
                  color: res.chat_setting.color,
                  case: res.chat_setting.case,
                  suggested_questions: res.suggested_questions,
                },
                filters: completeFiltersWithOptions(filters),
                name:
                  chatFoundInChatsHistory?.name ===
                  t('CHATBOT.NEW_CONSULTATION')
                    ? chatFoundInChatsHistory.name
                    : res.name,
                messages: {
                  list: [],
                  pagination: {
                    isLoading: false,
                    cursorPaginationRef: '',
                  },
                },
                suggested_questions: res.chat_setting.suggested_questions,
                isReviewed: true,
                isLoadingResponse: false,
                isOutOfPagination: true,
                isTemporary: false,
                isSendbarDisabled: false,
                document_text: res.document_text,
                attachments: [],
              }

              getMessages({
                payload: { uuid: referenceIdentifier, limit: 10 },
              })
                .then((res: any) => {
                  const newCursorPaginationRef: string =
                    res.headers['x-pagination-cursor']

                  const resReversed = res.data.reverse()

                  const messagesMap = resReversed.map(
                    (item: IChatbotMessage): IChatbotMessage => {
                      if (item.type === ChatbotMessageRols.USER) {
                        return {
                          ...item,
                          uuidChatOrigin: referenceIdentifier,
                          metadata: {},
                          fade: true,
                        }
                      } else {
                        return {
                          ...item,
                          uuidChatOrigin: referenceIdentifier,
                          prompt: item.prompt,
                          isTypingCompleted: true,
                          fade: true,
                          metadata: {
                            ...mapResponseToMetadata(item.metadata ?? {}),
                            isReviewed: !!item.metadata,
                          },
                        }
                      }
                    },
                  )

                  newInfoForChat = {
                    ...newInfoForChat,
                    messages: {
                      list: messagesMap,
                      pagination: {
                        isLoading: false,
                        cursorPaginationRef: newCursorPaginationRef,
                      },
                    },
                  }

                  // By default asume it's a chat out of pagination
                  dispatch(addNewChatToHistory(newInfoForChat))
                })
                .catch((err: any) => {
                  console.log(err)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  }, [referenceIdentifier])

  useEffect(() => {
    if (referenceIdentifier && referenceIdentifier !== 'loading') {
      const chatFoundInChatsHistory = chatsHistoryState.find(
        (item) => item.uuid === referenceIdentifier,
      )

      updateActiveChatWithHistoryChat(chatFoundInChatsHistory)
    }
  }, [chatsHistoryState])

  useEffect(() => {
    const observerEl = document.querySelector('.panel__mainContainer')

    const targetEl = document.querySelector(
      '.detailConsultation',
    )?.firstElementChild

    if (observerEl && targetEl) {
      const action = (): void => {
        if (
          !blockGetMessagesIntersection &&
          targetEl.classList.contains('chatbotMessage--user') &&
          activeChatState.uuid &&
          activeChatState.messages.pagination.cursorPaginationRef &&
          activeChatState.messages.pagination.cursorPaginationRef !== 'null' &&
          activeChatState.messages.list.length >= 10
        ) {
          setBlockGetMessagesIntersection(true)

          dispatch(
            setAreMessagesPaginationLoading({
              uuid: activeChatState.uuid,
              isLoading: true,
            }),
          )

          getMessages({
            payload: {
              uuid: activeChatState.uuid,
              limit: 10,
              cursor: activeChatState.messages.pagination.cursorPaginationRef,
            },
          })
            .then((res: any) => {
              const newCursorPaginationRef: string =
                res.headers['x-pagination-cursor']

              const resReversed = res.data.reverse()

              const oldPanelHeight = observerEl.scrollHeight
              // console.log('prevHeight', previousScrollHeight)

              const oldPanelScrollTop = observerEl.scrollTop
              // console.log('prevTop', previousScrollTop)

              const messagesMap = resReversed.map(
                (item: IChatbotMessage): IChatbotMessage => {
                  if (item.type === ChatbotMessageRols.USER) {
                    return {
                      ...item,
                      uuid: crypto.randomUUID(),
                      uuidChatOrigin: activeChatState.uuid,
                      fade: false,
                    }
                  } else {
                    return {
                      ...item,
                      uuid: crypto.randomUUID(),
                      uuidChatOrigin: activeChatState.uuid,
                      prompt: item.prompt,
                      isTypingCompleted: true,
                      fade: false,
                      metadata: {
                        ...mapResponseToMetadata(item.metadata ?? {}),
                        isReviewed: !!item.metadata,
                      },
                    }
                  }
                },
              )

              dispatch(
                addMessagesFromPagination({
                  uuid: activeChatState.uuid,
                  messages: messagesMap,
                  newCursorPaginationRef,
                }),
              )

              setTimeout(() => {
                const newPanelHeight = observerEl.scrollHeight

                observerEl.scrollTop =
                  oldPanelScrollTop + (newPanelHeight - oldPanelHeight)

                setBlockGetMessagesIntersection(false)
              }, 1)
            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() =>
              dispatch(
                setAreMessagesPaginationLoading({
                  uuid: activeChatState.uuid,
                  isLoading: false,
                }),
              ),
            )
        }
      }

      setIntersectionConfig({
        observerEl,
        targetEl,
        action,
      })
    }
  }, [activeChatState, blockGetMessagesIntersection])

  useEffect(() => {
    const subscriberSuggestedQuestions =
      detailConsultationLastQsObserver$.subscribe((data: string[]) => {
        setSuggestedQuestionsLastMessage(data)
      })

    return () => {
      subscriberSuggestedQuestions.unsubscribe()
    }
  }, [])

  const completeFiltersWithOptions = (
    filters: IFilterSelectedValues | null,
  ): IFilterSelectedValues => {
    if (!filters?.sources) return defaulfSelectedFilterValues

    return {
      ...filters,
      sources: filters.sources
        // .filter(source => source.key !== FilterSourceKeys.USER_DOCUMENT)
        .map((source) => {
          const filterOption = filterOptionsState.find(
            (opt) =>
              opt.key === source.key &&
              (!source.owner_id || opt.owner_id === source.owner_id),
          )

          if (source.key === FilterSourceKeys.USER_DOCUMENT) {
            return source
          }

          if (!filterOption) {
            console.warn(`No filter option found for source: ${source.key}`)
            return source
          }

          // Merge source with filter option data
          return {
            ...source,
            label: filterOption.label || source.label || filterOption.key,
            owner_id: filterOption.owner_id || source.owner_id,
            fields: source.fields || [],
          }
        }),
    }
  }

  const updateActiveChatWithHistoryChat = (
    chatFoundInChatsHistory: IChatHistoryItem | undefined,
  ): boolean => {
    if (
      chatFoundInChatsHistory?.isReviewed &&
      chatFoundInChatsHistory?.filters
    ) {
      const completedFilters = completeFiltersWithOptions(
        chatFoundInChatsHistory.filters,
      )

      dispatch(
        setActiveChat({
          ...chatFoundInChatsHistory,
          filters: completedFilters,
        }),
      )

      setIsLoading(false)

      return true
    }
    return false
  }

  return {
    isLoading,
    suggestedQuestionsLastMessage,
    activeChatState,
    chatSettingsState,
    dispatch,
  }
}

export default useDetailConsultation
